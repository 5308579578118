@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

@use '~@fortawesome/fontawesome-free/scss/fontawesome';
@use '~@fortawesome/fontawesome-free/scss/solid';
@use '~@fortawesome/fontawesome-free/scss/brands';

.button-link,
a {
    @apply text-sc-blue-300 hover:text-blue-300 underline hover:no-underline cursor-pointer;
}

blockquote {
    @apply mr-5 my-4 pl-4 border-solid border-l-4 border-gray-200;
}

.highlight-query {
    @apply not-italic underline decoration-2 decoration-yellow-400;
}

.hit {
    img  {
        @apply max-w-sm mx-auto;
    }

    ul {
        @apply list-disc ml-10;
    }
} 